

const DeliveryInfo = () => (
  <div className="modal__body">
    <h3 className="modal__title">Информация о доставке</h3>
    <div className="zones-table">
      <p className="zones-table__head">Минимальная сумма заказа</p>
      <p className="zones-table__head">Время ожидания</p>
      <p className="zones-table__head">Зона на карте города</p>
      <p className="zones-table__text">от 1000 р.</p>
      <p className="zones-table__text">1 час</p>
      <p className="zones-table__text">зеленая</p>
      <p className="zones-table__text">от 1390 р.</p>
      <p className="zones-table__text">1 час</p>
      <p className="zones-table__text">оранжевая</p>
      {/* <p className="zones-table__text">от 1990 р.</p>
      <p className="zones-table__text">1 час 15 мин.</p>
      <p className="zones-table__text">синяя</p>
      <p className="zones-table__text">от 3500 р.</p>
      <p className="zones-table__text">1 час 30 мин.</p>
      <p className="zones-table__text">красная</p> */}
    </div>
    <p className="modal__text">
      Самовывоз возможен с&nbsp;12:30 до&nbsp;01:30 по&nbsp;адресу: <br />
      г.&nbsp;Красноярск, ул.&nbsp;Карла&nbsp;Маркса&nbsp;149,
      2-ой&nbsp;этаж.
    </p>
    {/* <p className="modal__text modal__text--yellow">
      Скидка при&nbsp;самовывозе 15% на&nbsp;любой заказ.
    </p> */}
    <p className="modal__text">
      Вы можете выбрать удобное для&nbsp;вас время доставки при&nbsp;оформлении
      заказа.
    </p>
    <div style={{ position: "relative", overflow: "hidden" }}>
    <div style={{ position: "relative", overflow:"hidden" }}>
      <a href="https://yandex.ru/maps/62/krasnoyarsk/?utm_medium=mapframe&utm_source=maps" style={{ color: "#eee", fontSize:"12px", position:"absolute", top: "0px" }}>Красноярск</a>
      <a href="https://yandex.ru/maps/62/krasnoyarsk/?ll=92.834813%2C56.008957&mode=usermaps&source=constructorLink&um=constructor%3A713a266eec080161ea919b1b6a4207cfc0e1b555840b2303f9999c1e5f8e2152&utm_medium=mapframe&utm_source=maps&z=13"
      style={{color:"#eee", fontSize:"12px", position:"absolute", top:"14px"}}>Яндекс Карты — транспорт, навигация, поиск мест</a>
      <iframe title="maps" src="https://yandex.ru/map-widget/v1/?ll=92.834813%2C56.008957&mode=usermaps&source=constructorLink&um=constructor%3A713a266eec080161ea919b1b6a4207cfc0e1b555840b2303f9999c1e5f8e2152&z=13" width="560" height="400"  allowfullscreen="true" style={{position: "relative"}} />
    </div>
    </div>
    <h4 className="modal__subtitle">Условия доставки</h4>
    <ul className="modal__list">
      <li>Доставка бесплатная.</li>
      <li>
        Минимальная сумма заказа 1000&nbsp;руб. Стоимость заказа может быть
        увеличена, когда&nbsp;он сделан в&nbsp;удаленные районы города.
      </li>
    </ul>
    <p className="modal__small-text">
      В случае если минимальная сумма вашего заказа не соответствует бесплатной
      доставке в&nbsp;ваш район города, то стоимость платной доставки будет
      составлять от 150 рублей, в&nbsp;зависимости от удаленности (уточняйте
      у&nbsp; оператора).
    </p>
    <p className="modal__small-text">
      Оплатить заказ можно наличными или банковской картой при получении заказа
      от курьера. Мы принимаем карты Visa, MasterCard, МИР и Maestro.
    </p>
  </div>
);

export default DeliveryInfo
