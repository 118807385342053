
import { useState, useEffect } from "react";



const useLocalStorage = (key, defaultValue) => {

  const [value, setValue] = useState(() => {
    const saved = localStorage.getItem(key);
    if(saved != null) {
      const initial = JSON.parse(saved);
      return initial;
    }
    return defaultValue
  });

  useEffect(() => {
    // storing input nam

    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
export default useLocalStorage
