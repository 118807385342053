/* eslint-disable */

import video from '../../../assets/video/interier.mp4'
import  '../../../components/Showreel/Showreel.scss'
const Interier = () => (
  <div className="modal__body">
    <h3 className="modal__title">Добро пожаловать в ресторан XLFood.  </h3>

      <div className="showreel__block">
        {/* {isLoaded && content.uploadedVideo ? ( */}
        <video
          poster={video}
          className="showreel__video"
          autoPlay
          muted
          loop
          playsInline

        >
          <source src={video} />
        </video>
        {/* ) : (
          isLoaded && (
            <img
              className="showreel__image"
              src={`${AWS_URL}${content.uploadedImage.path}`}
              alt={content.alt}
            />
          )
        )} */}
      </div>


    <p className="modal__small-text">

    <p>Приветствуем вас в RocketFood, укромном уголке, где каждый момент пропитан атмосферной музыкой и вкусной кухней. </p><br/>

    <p>Расположенное на втором этаже кинотеатра "Луч", наше кафе создано для того, чтобы вы могли наслаждаться приятными встречами и моментами удовольствия.</p><br/>

    <p>Подарите себе чувство уюта на мягких диванах, которые создают идеальную атмосферу для дружеских посиделок или романтических свиданий. Наш контактный бар с радостью угостит вас разнообразными напитками, добавляя в ваш вечер шарм и веселье.</p><br/>


    <p>Погрузитесь в RocketFood, где каждый элемент, начиная от комфортной обстановки до вкусной еды, призван создать вам вечер полный наслаждений и незабываемых впечатлений. Добро пожаловать в уютное пространство, где ваши моменты станут особенными.</p><br/>

    </p>

  </div>
)

export default Interier












