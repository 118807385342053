/* eslint no-underscore-dangle: 0 */

const showMenuCarousel = (arrCategories, block) => {
  const arr = [
    '6131dad8c90cc43b38bda99a',
    '6131e0650e90c431d0cf833d',
    '612367adeebe1a0bf4ebc120'
  ];

  let arrNew = [];

  if(block === "head") {
    arrNew =  arrCategories.filter((item) => !arr.find((item1) => item1 === item._id))
  } else {

    arrNew =  arrCategories.filter((item) => arr.find((item1) => item1 === item._id))

  }

  return arrNew
}


export  default showMenuCarousel
