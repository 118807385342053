

import "./DeliveryAddress.scss";
import { useContext, useState,useEffect,useRef } from "react";
import { useDispatch } from 'react-redux'
import PropTypes from "prop-types";
import {
  YMaps,
  Map,
  Placemark,
  SearchControl,
  TypeSelector
} from "@pbe/react-yandex-maps";
import deliveryIcon from "../../../assets/img/delivery-icon.svg";
import storeIcon from "../../../assets/img/storefront-icon.svg";
import searchIcon from "../../../assets/img/search_normal.svg";
import useLocalStorage from "../../../hooks/useLocalStorege";
import AdressContext from "../../../hooks/adressContext";

const API_KEY = "c72dc69b-b42b-495c-a0bf-eb27a9fa3719";
const DeliveryAddress = ({ handleClose }) => {
  const ref = useRef();
  const ref2 = useRef();
  const ymaps = useRef(null);
  const [value, setValue] = useState("");

  const [newCoords, setNewCoords] = useLocalStorage("coords_current",[
    56.009265, 92.856255
  ]);

  const center = [56.009265, 92.856255];
  const images = [...Array(5)].map((n, i) => {
    const letter = String.fromCharCode(i + 97);
    return `https://img.icons8.com/ios-filled/2x/marker-${letter}.png`;
  });

  const [diliveryType, setDiliveryType] = useLocalStorage("dilivery_type", false);
  const [adressOrder,setAdressOrder] = useContext(AdressContext);
  const [adress,setAdress] = useState("");
  const [options, setOptions] = useState([]);
  const [optionsFocus, setOptionsFocus] = useState(false);

  const handleOption = (e,curentOption) => {

      setValue(() =>  ` ${e.target.innerText}`);
      const obg = curentOption

      if(obg) {

        const coords = obg.Point.pos
        .split(" ")
        .map((item) => Number(item))
        .reverse();
        setNewCoords(() => coords);
      }
      setAdress(e.target.innerText)
      setOptionsFocus(false)
  }
  const handleText = (e) => {
      if(e.target.value.length > 5)
        setValue(() =>  `Красноярск ${e.target.value}`);

      setAdress(e.target.value);
  }
  const handleButtonClick = () => {
    // Вызываем метод handleClose при клике на кнопку
    handleClose();
  };
  useEffect(() => {

    (async () => {
      try {
        if (value) {
          const res = await fetch(
            `https://geocode-maps.yandex.ru/1.x/?apikey=${API_KEY}&format=json&geocode=${value}`
          );
          const data = await res.json();
          const collection = data.response.GeoObjectCollection.featureMember.map(
            (item) => item.GeoObject
          ).filter(x => x.description.includes('Красноярск'));
          console.log(collection)
          setOptions(() => collection);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [value]);
  return (
  <div className="dilivery__body">
    <div className="dilivery__header">
      <div className="dilivery__header-buttons">
          <button type="button" className={diliveryType ? "delivery_button" : "delivery_button delivery_button_active"}
            onClick={() => {

              setAdress('')
              setDiliveryType(false)

            }}
          ><img src={deliveryIcon} alt="Доставка" />Доставка</button>
          <button type="button" className={diliveryType ? "delivery_button delivery_button_active" : "delivery_button"}
            onClick={() => {
              setAdress('улица Карла Маркса, 149')
              setDiliveryType(true)}
            }
          ><img src={storeIcon} alt="Доставка" />Самовывоз</button>
      </div>
      <div className="dilivery__header-inputs">
          <div className="textarea__adress_group" onFocus={() => {setOptionsFocus(true)}}  onBlur={(e)=> {
            console.log(e);
            if(e.target)
            if (e.target.className !== "textarea__adress")
              setOptionsFocus(false)
         }}>
            <img src={searchIcon} alt="Поиск" />
            <input
              value={adress}
              onChange={handleText}



              className="textarea__adress" placeholder="Укажите адрес (улица, дом)" />
            {optionsFocus &&
            <div className="input_dropdown">
              {options.map((item) => (<button type="button" className="input_dropdown_input" onClick={(e) => {console.log("CLICK"); handleOption(e,item);}}>{item.name}</button>))}
            </div>}

          </div>

          <button type="button" className="delivery_button delivery_button_save" onClick={handleButtonClick }>
            Сохранить адрес</button>
      </div>
    </div>
    <YMaps
      query={{
        load: "package.full",
        apikey: API_KEY
      }}
    >

      <Map
       className="dilivery__ymaps"
        instanceRef={ref2}
        state={{
          center: newCoords,
          zoom: 12,
          controls: ["zoomControl"]
        }}
        onLoad={(e) => {
          console.log('LOADED')
          ymaps.current = e;

          e.geocode(newCoords).then((res) => {
            const firstGeoObject = res.geoObjects.get(0);
            const newAddress = [
              firstGeoObject.getLocalities().length
                ? firstGeoObject.getLocalities()
                : firstGeoObject.getAdministrativeAreas(),
              firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
              firstGeoObject.getPremiseNumber()
            ]
              .filter(Boolean)
              .join(", ");

            setAdress(() => newAddress.replace('Красноярск, ',''));
            setValue(() => newAddress);
          });
        }}
        width="100vw"
        height="100vh"
        modules={["control.ZoomControl"]}
        onClick={(event) => {
          const coords = event.get("coords");
          setNewCoords(() => coords);

          ymaps.current.geocode(coords).then((res) => {
            const firstGeoObject = res.geoObjects.get(0);
            const newAddress = [
              firstGeoObject.getLocalities().length
                ? firstGeoObject.getLocalities()
                : firstGeoObject.getAdministrativeAreas(),
              firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
              firstGeoObject.getPremiseNumber()
            ]
              .filter(Boolean)
              .join(", ");
            ref.current.getMap().hint.open(coords, newAddress);
            setAdress(() => newAddress.replace('Красноярск, ',''));
            setValue(() => newAddress);
          });
        }}
      >
        {/* <ZoomControl options={{ float: "right" }} /> */}
        <Placemark
          instanceRef={ref}
          onDragEnd={(event) => {
            const coords = event.get("coords");
            setNewCoords(() => coords);
            ymaps.current.geocode(coords).then((res) => {
              const firstGeoObject = res.geoObjects.get(0);
              const newAddress = [
                firstGeoObject.getLocalities().length
                  ? firstGeoObject.getLocalities()
                  : firstGeoObject.getAdministrativeAreas(),
                firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
                firstGeoObject.getPremiseNumber()
              ]
                .filter(Boolean)
                .join(", ");
              ref.current.getMap().hint.open(coords, newAddress);
              setAdress(() => newAddress.replace('Красноярск, ',''));
              setValue(() => newAddress);
            });
          }}
          geometry={newCoords}
          options={{
            iconImageSize: [30, 30],
            draggable: true,
            preset: "islands#personIcon",
            hideIconOnBalloonOpen: false,
            openEmptyHint: true,
            iconColor: 'black'
          }}
          properties={{
            iconCaption: adress,
            hintContent: adress
          }}
        />
        <Placemark
          onClick={() => {
            setAdress('улица Карла Маркса, 149')
            setValue('улица Карла Маркса,149 ')
            setDiliveryType(false)
          }}
          key={1}
          defaultGeometry={[56.009265, 92.856255]}
          options={{
            iconImageSize: [10, 10],
            preset: "islands#foodIcon",
            iconColor: 'orange'
          }}
        />
      </Map>
    </YMaps>
  </div>
)}

DeliveryAddress.propTypes = {
  handleClose: PropTypes.func,
};
DeliveryAddress.defaultProps = {
  handleClose: null,
};


export default DeliveryAddress
