import { useSelector } from "react-redux";
import { useState, createContext, useContext,useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import sendMetrics from "../../utils/yandexMetric";
import Modal from "../Modal/Modal";
import Promotions from "../Modal/ModalBody/Promotions";
import DeliveryInfo from "../Modal/ModalBody/DeliveryInfo";
import logo from "../../assets/img/logo.png";
import "./Header.scss";
import Burger from "../../assets/img/icon_burger.svg";
import Button from "../Button/Button";
import useLocalStorage from "../../hooks/useLocalStorege";
import AdressContext from "../../hooks/adressContext";
import Interier from "../Modal/ModalBody/Interier";

const Header = () => {
  const cartTotalCount = useSelector(({ cart }) => cart.totalCount);

  const clickPhone = () => sendMetrics("reachGoal", "clik_nomer");
  const [isBurger,setIsBurger] = useState(false)
  const [isBurgerStart,setIsBurgerStart] = useState(false)
  const addressButton = useRef();
  const [adressOrder, setAdressOrder] = useLocalStorage("save_address",'');
  const clickBurger = (e) => {
    setIsBurger(!isBurger)
    setIsBurgerStart(true)
  };
  const clickAdrees= (e) => {
    navigator.clipboard.writeText('ул. Парижской Коммуны, 31 (этаж Цокольный)')
  };

  // useEffect(() => {
  //   console.log("LOAD")
  //   console.log(adressOrder)
  // });
  return (
    <header className="header">
      <AdressContext.Provider value={ [adressOrder,setAdressOrder]  }>
      <div className="header__container">
        <div className="header__left">
          <div className="header__left_group">
            <Button className="header_burger"  hover={{background: "#EBEBEB",}}  onClick={clickBurger}>
              {isBurger ?
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 14L14 1" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14 14L1 1" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

              :
              <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1.61841" y1="1.25" x2="17.3816" y2="1.25" stroke="#2B2B2B" strokeWidth="1.5" strokeLinecap="round"/>
                <line x1="1.61841" y1="6.25" x2="17.3816" y2="6.25" stroke="#2B2B2B" strokeWidth="1.5" strokeLinecap="round"/>
                <line x1="1.61841" y1="11.25" x2="17.3816" y2="11.25" stroke="#2B2B2B" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
              }
            </Button>
            <Link to="/" className="header__logo">
              <img src={logo} alt="Логотип XLFood" />
            </Link>
          </div>
          <Modal containerClass="header__modal" childrenDelivery="true" btnImage={(
            <Button href={addressButton} className="header_adress" hover={{background: "#EBEBEB",}}   >
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 13.5256C9.04751 13.5256 7.45251 11.9398 7.45251 9.97813C7.45251 8.01646 9.04751 6.4398 11 6.4398C12.9525 6.4398 14.5475 8.02563 14.5475 9.9873C14.5475 11.949 12.9525 13.5256 11 13.5256ZM11 7.8148C9.80835 7.8148 8.82751 8.78646 8.82751 9.9873C8.82751 11.1881 9.79918 12.1598 11 12.1598C12.2008 12.1598 13.1725 11.1881 13.1725 9.9873C13.1725 8.78646 12.1917 7.8148 11 7.8148Z"
                fill="#f1f1f1"/>
                <path d="M11 21.3998C9.64337 21.3998 8.27754 20.8865 7.21421 19.869C4.51004 17.2656 1.52171 13.1131 2.64921 8.1723C3.66671 3.6898 7.58087 1.6823 11 1.6823C11 1.6823 11 1.6823 11.0092 1.6823C14.4284 1.6823 18.3425 3.6898 19.36 8.18146C20.4784 13.1223 17.49 17.2656 14.7859 19.869C13.7225 20.8865 12.3567 21.3998 11 21.3998ZM11 3.0573C8.33254 3.0573 4.90421 4.47813 3.99671 8.4748C3.00671 12.7923 5.72004 16.514 8.17671 18.8698C9.76254 20.4006 12.2467 20.4006 13.8325 18.8698C16.28 16.514 18.9934 12.7923 18.0217 8.4748C17.105 4.47813 13.6675 3.0573 11 3.0573Z"
                fill="#f1f1f1"/>
                </svg>
                <p className="header_adress_text">{adressOrder !== "" ? adressOrder : "Укажите адрес"}</p>

              </Button>
          )} btnStyle={{ color: "primary" }} />

        </div>
        <div className="header__right">
          <a
            className="header__phone"
            href="tel:+7 (391) 271-77-88"
            onClick={clickPhone}
          >
            +7 (391) 271-77-88
          </a>

          <Button className="header_adress-mob" hover={{background: "#EBEBEB",}}   >
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 13.5256C9.04751 13.5256 7.45251 11.9398 7.45251 9.97813C7.45251 8.01646 9.04751 6.4398 11 6.4398C12.9525 6.4398 14.5475 8.02563 14.5475 9.9873C14.5475 11.949 12.9525 13.5256 11 13.5256ZM11 7.8148C9.80835 7.8148 8.82751 8.78646 8.82751 9.9873C8.82751 11.1881 9.79918 12.1598 11 12.1598C12.2008 12.1598 13.1725 11.1881 13.1725 9.9873C13.1725 8.78646 12.1917 7.8148 11 7.8148Z" fill="#292D32"/>
              <path d="M11 21.3998C9.64337 21.3998 8.27754 20.8865 7.21421 19.869C4.51004 17.2656 1.52171 13.1131 2.64921 8.1723C3.66671 3.6898 7.58087 1.6823 11 1.6823C11 1.6823 11 1.6823 11.0092 1.6823C14.4284 1.6823 18.3425 3.6898 19.36 8.18146C20.4784 13.1223 17.49 17.2656 14.7859 19.869C13.7225 20.8865 12.3567 21.3998 11 21.3998ZM11 3.0573C8.33254 3.0573 4.90421 4.47813 3.99671 8.4748C3.00671 12.7923 5.72004 16.514 8.17671 18.8698C9.76254 20.4006 12.2467 20.4006 13.8325 18.8698C16.28 16.514 18.9934 12.7923 18.0217 8.4748C17.105 4.47813 13.6675 3.0573 11 3.0573Z" fill="#292D32"/>
              </svg>
            </Button>
          <div className="header__cart">
            <Link to="/cart">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_509_67)">
                <path d="M10.7928 24.5621C11.3584 24.5621 11.8169 24.1036 11.8169 23.5381C11.8169 22.9724 11.3584 22.5139 10.7928 22.5139C10.2273 22.5139 9.7688 22.9724 9.7688 23.5381C9.7688 24.1036 10.2273 24.5621 10.7928 24.5621Z" stroke="#ffffff" strokeWidth="1.94561" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M22.0576 24.5621C22.6231 24.5621 23.0816 24.1036 23.0816 23.5381C23.0816 22.9724 22.6231 22.5139 22.0576 22.5139C21.492 22.5139 21.0334 22.9724 21.0334 23.5381C21.0334 24.1036 21.492 24.5621 22.0576 24.5621Z" stroke="#ffffff" strokeWidth="1.94561" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2.60059 3.05681H6.69682L9.4413 16.769C9.53494 17.2404 9.79143 17.664 10.1659 17.9654C10.5403 18.2669 11.0088 18.4269 11.4894 18.4178H21.4432C21.9239 18.4269 22.3924 18.2669 22.7669 17.9654C23.1412 17.664 23.3977 17.2404 23.4914 16.769L25.1298 8.17711H7.72088" stroke="#ffffff" strokeWidth="1.94561" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_509_67">
                <rect width="26.7499" height="26.75" fill="white" transform="translate(0.549561 0.625)"/>
                </clipPath>
                </defs>
                </svg>
            </Link>
            {cartTotalCount !== 0 && <div className="header__cart-count"><span >{cartTotalCount}</span></div> }
          </div>
        </div>
      </div>
      { isBurgerStart &&
      <div className={isBurger ? "burger burger-open":"burger burger-close"}>
        <div className="burger__body">
          <div className="burger__body_group">
            <Modal btnText="Акции и скидки" btnStyle={{ color: "primary" }}>
              <Promotions />
            </Modal>
            <Modal btnText="Доставка" btnStyle={{ color: "primary" }}  >
              <DeliveryInfo />
            </Modal>
            <Modal btnText="Интерьер" btnStyle={{ color: "primary" }}  >
             <Interier />
            </Modal>
          </div>
          <div className="burger__body_group">
            <div className="burger__body_group_top">
              <p>Заказать</p>
              <a
                className="header__phone"
                href="tel:+7 (391) 271-77-88"
                onClick={clickPhone}>
                +7 (391) 271-77-88
              </a>
            </div>
            <div className="burger__body_group_bottom">
                <p>Eжедневно, 12:00–02:00</p>
                <Button onClick={clickAdrees}>г. Красноярск,
		<br /> улица Карла Маркса, 149<br />(Кинокомплекс ЛУЧ)</Button>
            </div>
          </div>
        </div>
      </div>  }
      </AdressContext.Provider>
    </header>
  );
};

export default Header;
