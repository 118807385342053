import sendMetrics from "../../utils/yandexMetric";
import iconVK from "../../assets/img/iconVK.svg";
import iconInstagram from "../../assets/img/iconInstagram.svg";
import gangLogo from "../../assets/img/gang-logo.svg";

import "./Footer.scss";

const Footer = () => {
  const clickPhone = () => sendMetrics("reachGoal", "clik_nomer");

  return (
    <footer className="footer">
      <div className="footer__container">
        <div>
          <a
            className="footer__phone"
            href="tel:+7 (391) 271-77-88"
            onClick={clickPhone}
          >
            +7 (391) 271-77-88
          </a>
          <ul className="footer__socials">
            <li>
              <a href="https://vk.com/rocket.food" target="_blank" rel="noreferrer">
                <img src={iconVK} alt="Группа ВКонтакте" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/rocketstreetfood/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconInstagram} alt="Instagram" />
              </a>
            </li>
          </ul>
        </div>

        <a
          href="/Политика в отношении обработки персональных данных XL.pdf"
          target="_blank"
          rel="noreferrer"
          className="footer__policy"
          id="footer__politika"
        >
          Политика конфиденциальности
        </a>

        <p className="footer__policy" id="footer__policy_info">
        ИП  Недбай Кирилл Сергеевич<br />
        ИНН: 246000346681 <br />
        Юридический адрес компании:  <br />г. Красноярск, улица Карла Маркса,  <br />149(Кинокомплекс ЛУЧ)
        </p>
      </div>
    </footer>
  );
};

export default Footer;
