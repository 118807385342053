import { useEffect, useRef, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./Categories.scss";
import showMenuCarousel from "../../utils/utils";


const Categories = ({type}) => {
  const [active, setActive] = useState(0);
  const { items: categoryItems, isLoaded } = useSelector(
    ({ categories }) => categories
  ) || [];
  const activeId = useSelector(({ activeSection }) => activeSection.id);
  const categoriesRef = useRef();

  useEffect(() => {
    setActive(activeId);
  }, [activeId]);

  return (
    <div className="categories" ref={categoriesRef}>
      <ul className="categories__list">
        {isLoaded &&
          categoryItems.length > 0 && showMenuCarousel(categoryItems, type).map((category) => (
            <li
              key={uuidv4()}
              className={classNames({ active: +active === category.index })}
            >
              <HashLink smooth to={`#${category.index}`}>
                {category.name}
              </HashLink>
            </li>
          ))}
      </ul>
    </div>
  );
};

Categories.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Categories;

